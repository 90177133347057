<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useElementSize } from '@vueuse/core';
import dompurify from 'dompurify';
import { debounce, isEqual, merge } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const props = defineProps({
  configuration: {
    type: Object,
    required: true,
  },
  id: {
    type: String,
  },
  chart_config: {
    type: Object,
    default: () => {},
  },
  table_chart: {
    type: Boolean,
    default: false,
  },
  handle_print: {
    type: Boolean,
    default: true,
  },
  group_key: {
    type: String,
    default: '',
  },
});

const dashboard_selected_range = inject('dashboardSelectedRange');

const dashboard_store = useDashboardStore();

const chart_widget = ref(null);
const { width, height } = useElementSize(chart_widget);

const {
  fusion_chart_state,
} = storeToRefs(dashboard_store);

let chart = null;
const chart_width = computed(() => {
  if (props.configuration.renderAt !== 'chart-preview')
    return (width.value * 0.9) || '100%';

  return '100%';
});
const chart_height = computed(() => {
  const mobile_chart_height = props.configuration.type === 'angulargauge' ? 20 : 15;
  if (props?.configuration?.type === 'msspline')
    return '70%';
  if (dashboard_store.is_mobile_view)
    return (props?.configuration?.dimensions?.h * mobile_chart_height);
  if (props.configuration.renderAt !== 'chart-preview')
    return (height.value * 0.9) || '100%';

  return '70%';
});

function toolText() {
  if (['mscombidy2d', 'mscombi2d'].includes(props.configuration.type)) {
    return `<div>
      <table id='dataTable'>
        <tr>
          <td style="font-weight:700">$seriesName ($label):</td>
          <td style="font-weight:200">$value</td>
        </tr>
      </table>
    </div>`;
  }
  else if (props.group_key && (props.group_key !== 'None' && props.group_key !== 'none')) {
    return `<div><table id='dataTable'><tr>
  <td style="font-weight:700">$xAxisName:</td><td style="font-weight:200">$label</td></tr><tr>
    <td style="font-weight:700" class='capitalize'>${props.group_key.split('_').join(' ')}:</td><td style="font-weight:200">$seriesName</td></tr><tr><td style="font-weight:700">$yAxisName:</td><td style="font-weight:200">$value</td></tr></table></div>`;
  }
  else {
    return '<div><table id=\'dataTable\' ><tr class=\'\'><td style="font-weight:700">$xAxisName:</td><td style="font-weight:200">$label</td></tr><tr><td style="font-weight:700">$yAxisName:</td><td style="font-weight:200">$value</td></tr></table></div>';
  }
}

const graph_data = computed(() => merge({
  dataFormat: 'json',
  width: chart_width.value,
  height: chart_height.value,
  renderType: 'fusion-chart',
  dataLoadStartMessage: 'Please wait, chart is loading the data...',
  dataSource: {
    chart: {
      theme: 'fusion',
      numbersuffix: '',
      useEllipsesWhenOverflow: '0',
      yAxisValuesStep: '2',
      gaugeOuterRadius: 150,
      connectNullData: 1,
      drawAnchors: 0,

      caption: props.chart_config?.title || '',
      showlegend: props.chart_config?.legend_position === 'none' ? '0' : '1',
      legendPosition: !props.chart_config?.legend_position || props.chart_config?.legend_position === 'none' || !props.chart_config?.legend_position ? 'bottom' : props.chart_config?.legend_position,
      formatNumberScale: props.chart_config?.prettify_values === false ? '0' : '1',
      ...(props.chart_config?.chart_display_mode === 'Stacked' ? { minPlotHeightForValue: '10' } : {}),
      plotToolText: dompurify.sanitize(toolText()),
      showValues: ['column2d', 'doughnut2d'].includes(props.configuration.type) ? '1' : '0',
      showSum: '1',
      labelPosition: 'outside',
      plotColorinTooltip: '0',
      enableMultiSlicing: '0',
      slicingDistance: '0',
      showPercentValues: '0',
      drawCrossLine: ['mscombidy2d', 'mscombi2d', 'line', 'msline', 'area2d', 'msarea'].includes(props.configuration.type) ? '1' : '0',
      toolTipBgColor: '#575e6b',
      toolTipColor: '#fff',
      tooltipborderradius: '5',
    },
  },
}, props.configuration));

async function renderGraph() {
  if (typeof fusion_chart_state.value.VueFusionChartsComponent !== 'function')
    return;

  if (props.configuration?.type === 'angulargauge') {
    fusion_chart_state.value.VueFusionChartsComponent(
      fusion_chart_state.value.FusionCharts,
      fusion_chart_state.value.Widgets,
      fusion_chart_state.value.FusionTheme,
    );
  }
  else {
    fusion_chart_state.value.VueFusionChartsComponent(fusion_chart_state.value.FusionCharts, fusion_chart_state.value.Charts, fusion_chart_state.value.FusionTheme);
  }

  chart = new fusion_chart_state.value.FusionCharts(graph_data.value);
  await chart.render();

  if (props?.id !== 'preview' && props.handle_print) {
    const cpy = { ...graph_data.value };
    cpy.height = '100%';
    cpy.width = '100%';
    cpy.dashboard_selected_range = dashboard_selected_range;
    dashboard_store.update_print_map(props.id, cpy);
    dashboard_store.update_new_print_map((cpy.chart_name), {
      widget_type: 'chart',
      type: props?.configuration?.type,
      dataSource: cpy.dataSource,
    });
  }
}

const resizeWidget = debounce(async () => {
  if (dashboard_store.is_mobile_view)
    return;
  if (props.configuration.type !== 'angulargauge')
    chart?.resizeTo('100%', '80%');

  else
    new fusion_chart_state.value.FusionCharts(graph_data.value).render();
}, 150);

watch([chart_width, chart_height], () => resizeWidget());

watch(() => props.configuration.dataSource, (new_val, old_val) => {
  if (!isEqual(new_val, old_val)) {
    setTimeout(() => {
      renderGraph();
    }, 0);
  }
}, { immediate: true, deep: false });
</script>

<template>
  <div
    v-show="props.configuration.renderAt"
    :id="props.configuration.renderAt"
    ref="chart_widget"
    class="flex justify-center w-full items-center"
    :class="{ 'h-full': configuration.type !== 'msspline' }"
  />
</template>

<style lang="scss">
#dataTable, tr {
  padding: 4px;
  table-layout: auto;
}

// TODO: REMOVE WHEN FUSIONCHART IS UPDATED
.fusioncharts-container g[class*="legendGroup"] text {
    transform: translateY(-7px) !important; /* Moves only legend text upwards */
}
</style>
